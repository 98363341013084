@import '@angular/material/theming';
@import 'variables.scss';
/* //Typoghraphy */
 
$typo: mat-typography-config(
$display-4: mat-typography-level(112px, 112px, 300),
$display-3: mat-typography-level(56px, 56px, 400),
$display-2: mat-typography-level(45px, 48px, 400),
$display-1: mat-typography-level(34px, 40px, 400),
$headline: mat-typography-level(24px, 32px, 400),
$title: mat-typography-level(20px, 32px, 500),
$subheading-2: mat-typography-level(16px, 28px, 400),
$subheading-1: mat-typography-level(15px, 24px, 400),
$body-2: mat-typography-level(14px, 24px, 500),
$body-1: mat-typography-level(14px, 20px, 400),
$caption: mat-typography-level(12px, 20px, 400),
$button: mat-typography-level(14px, 14px, 500),
// Line-height must be unit-less fraction of the font-size.
$input: mat-typography-level(14px, 1.125, 400)
);
 
$primary-color: (
    50 : #e5e7f1,
    100 : #bec3dc,
    200 : #939bc5,
    300 : #6872ad,
    400 : #47549c,
    500 : #27368a,
    600 : #233082,
    700 : #1d2977,
    800 : #17226d,
    900 : #0e165a,
    A100 : #9099ff,
    A200 : #5d6bff,
    A400 : #2a3cff,
    A700 : #1025ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$accent-color: (
    50 : #fae5ef,
    100 : #f2bed8,
    200 : #e993be,
    300 : #e068a3,
    400 : #da4790,
    500 : #d3277c,
    600 : #ce2374,
    700 : #c81d69,
    800 : #c2175f,
    900 : #b70e4c,
    A100 : #ffe5ed,
    A200 : #ffb2cb,
    A400 : #ff7fa8,
    A700 : #ff6597,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$primary : mat-palette($primary-color);
$accent : mat-palette($accent-color);
 
$mytheme: mat-light-theme($primary, $accent);

@include mat-core($typo);
 
@include angular-material-theme($mytheme);
