@font-face {
    font-family: 'SourceSansPro-Black';
    src: url('./SourceSansPro-Black.ttf') format('truetype');
}
 
@font-face {
    font-family: 'SourceSansPro-Regular';
    src: url('./SourceSansPro-Regular.ttf') format('truetype');
}
 
@font-face {
    font-family: 'SourceSansPro-ExtraLight';
    src: url('./SourceSansPro-ExtraLight.ttf') format('truetype');
}
 
@font-face {
    font-family: 'SourceSansPro-Light';
    src: url('./SourceSansPro-Light.ttf') format('truetype');
}
 