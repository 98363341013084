@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Montserrat-Black';
    src: url('./Montserrat-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-BlackItalic';
    src: url('./Montserrat-BlackItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url('./Montserrat-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-BoldItalic';
    src: url('./Montserrat-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-ExtraBold';
    src: url('./Montserrat-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-ExtraBoldItalic';
    src: url('./Montserrat-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-ExtraLight';
    src: url('./Montserrat-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-ExtraLightItalic';
    src: url('./Montserrat-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-Italic';
    src: url('./Montserrat-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-Light';
    src: url('./Montserrat-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-LightItalic';
    src: url('./Montserrat-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-Medium';
    src: url('./Montserrat-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-MediumItalic';
    src: url('./Montserrat-MediumItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-Regular';
    src: url('./Montserrat-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('./Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-SemiBoldItalic';
    src: url('./Montserrat-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-Thin';
    src: url('./Montserrat-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-ThinItalic';
    src: url('./Montserrat-ThinItalic.ttf') format('truetype');
}