/* You can add global styles to this file, and also import other style files */
:root {
  --basefont: 14px;
  --headerimage: url(assets/images/header-seprator.png);
  --primaryfontfamily: "Montserrat-Medium";
  //--secondaryfontfamily: "Roboto-Regular";

  // Initialize Classic theme variables here
  --classicStepperBorderRadius: 50%;

  // Initialize Starling Theme Variables here
  --starlingBackgroundColor: url(assets/images/starling/starling-background-image.png);

  // GLobal Color Scheme Variables here
  --primaryColor: #294d61;
  --secondaryColor: #87b348;
  --backgroundColor: #f8f8f8;
  --surfaceColor: #ffffff;

  --typographyPrimaryColor: #ffffff;
  --typographySecondaryColor: #ffffff;
  --typographyBackgroundColor: #333333;
  --typographySurfaceColor: #999999;
  --typographyDisableColor: #adadad;
  --typographyErrorColor: #e94c4c;
  --typographySuccessColor: #7ee745;

  // Background
  --loginPageBackground: var(--primaryColor);
  --appBackground: var(--primaryColor);
  --brandLogoWithText: url(assets/images/logo-white.svg);
  --brandLogo: url(assets/images/logo-icon.svg);

  --Modren-heading:'Open sans';
  --Modren-content:'Open sans';
  --globalFontSizeContent:14px;

  // color code
  --backgroundColor100: #f8f8f8;
  --backgroundColor200: #e8e8e8;
  --backgroundColor300: #d8d8d8;
  --backgroundColor500: #b8b8b8;
  --backgroundColor700: #989898;
  --backgroundColor900: #787878;
  --primaryColor100: #80afc9;
  --primaryColor200: #6aa1c0;
  --primaryColor300: #5393b6;
  --primaryColor500: #3c718e;
  --primaryColor700: #294d61;
  --primaryColor900: #162934;
  --iconColor100: #d9d9d9;
  --iconColor200: #c9c9c9;
  --iconColor300: #b9b9b9;
  --iconColor500: #999999;
  --iconColor700: #797979;
  --iconColor900: #595959;
  --secondaryColor100: #c2d9a1;
  --secondaryColor200: #b3d08a;
  --secondaryColor300: #a5c774;
  --secondaryColor500: #87b348;
  --secondaryColor700: #658636;
  --secondaryColor900: #425823;
  --surfaceColor100: #ffffff;
  --surfaceColor200: #efefef;
  --surfaceColor300: #dfdfdf;
  --surfaceColor500: #bfbfbf;
  --surfaceColor700: #9f9f9f;
  --surfaceColor900: #808080;
  --typographyBackgroundColor100: #939393;
  --typographyBackgroundColor200: #838383;
  --typographyBackgroundColor300: #737373;
  --typographyBackgroundColor500: #535353;
  --typographyBackgroundColor700: #333333;
  --typographyBackgroundColor900: #131313;
  --typographyDisableColor100: #cdcdcd;
  --typographyDisableColor200: #bdbdbd;
  --typographyDisableColor300: #adadad;
  --typographyDisableColor500: #8d8d8d;
  --typographyDisableColor700: #6d6d6d;
  --typographyDisableColor900: #4d4d4d;
  --typographyErrorColor100: #f7bebe;
  --typographyErrorColor200: #f3a1a1;
  --typographyErrorColor300: #f08585;
  --typographyErrorColor500: #e94c4c;
  --typographyErrorColor700: #da1b1b;
  --typographyErrorColor900: #a21414;
  --typographyPrimaryColor100: #ffffff;
  --typographyPrimaryColor200: #efefef;
  --typographyPrimaryColor300: #dfdfdf;
  --typographyPrimaryColor500: #bfbfbf;
  --typographyPrimaryColor700: #9f9f9f;
  --typographyPrimaryColor900: #808080;
  --typographySecondaryColor100: #ffffff;
  --typographySecondaryColor200: #efefef;
  --typographySecondaryColor300: #dfdfdf;
  --typographySecondaryColor500: #bfbfbf;
  --typographySecondaryColor700: #9f9f9f;
  --typographySecondaryColor900: #808080;
  --typographySurfaceColor100: #d9d9d9;
  --typographySurfaceColor200: #c9c9c9;
  --typographySurfaceColor300: #b9b9b9;
  --typographySurfaceColor500: #999999;
  --typographySurfaceColor700: #797979;
  --typographySurfaceColor900: #595959;
  --typographySuccessColor100: #a5ee7d;
  --typographySuccessColor200: #92eb61;
  --typographySuccessColor300: #7ee745;
  --typographySuccessColor500: #5bd11b;
  --typographySuccessColor700: #439914;
  --typographySuccessColor900: #2a600c;

    //v4
    --html-font-size: 100%;
    --fluid-width: var(--html-font-size);
    --body-font-size: 12px;
    --primaryColor: #d14141;
    --secondaryColor: #0F3FE0;;
    --typographyPrimaryColor: #333860;
    --typographySecondaryColor: #FFFFFF;
    --primaryColor:rgb(24, 24, 23);
    --typographyDisableColor: #6e5c46;
    --typographyErrorColor: #ff3434;
    --typographySuccessColor: #21a67a;
    --typographySurfaceColor:#838687;
    --typographyDisableColor:rgb(124, 33, 33);
    --white-color:red;



    --warning-color: #ffcc41;
    --success-color: #21a67a;
    --bg-color: #f0f3f8;
    --white-color: #ffffff;
    --text-on-teritary: #a0a7be;
    --progress-bar: #b9dffe;
    --weight-thin: 300;
    --weight-regular: 400;
    --weight-semi-bold: 600;
    --weight-bold: 700;

    --label-font-size-small: 10px;
    --label-font-size-medium: 12px;
    --label-font-size-large: 18px;
    //Themes
    //Global fonts
    --font-family: 'Roboto';
    --font-size-heading-extra-large: 20px;
    --font-size-heading-large: 18px;
    --font-size-heading-medium:16px;
    --font-size-heading-small:14px;
    --font-size-regular:12px;
    --font-size-medium:10px;
    --font-size-small:8px;
    --font-weight-thin:300;
    --font-weight-normal:400;
    --font-weight-regular:500;
    --font-weight-semi-bold:600;
    --font-weight-large:700;


    // sidebar variables
    --sideBarSurfaceColor: rgba(36, 0, 82);
    --sideBarOpacity:0.7;
    --sideBarColor:#ffffff;
    --sideBarNonActiveColor:white;
    --sideBarIconActiveColor:grey;
    --sideBarIconDisableColor:grey;

     // hover
     --hoverBackgroundColor:black;
     --hoverBackgroundOpacity:black;
     --hoverEditableTextColor:black;
     --hoverReadOnlyTextColor:black;
     --hoverDisabledTextColor:black;
     --hoverHelpertextColor:black;
     --hoverErrortextColor:black;
     --hoverInfoIconActiveColor:black;
     --hoverInfoIconColor:black;
     --hoverInfoIconDisableColor:black;
     --hoverActionIconActiveColor:black;
     --hoverActionIconColor:black;
     --hoverActionIconDisbaleColor:black;

     //Workspace
     --workSpaceSurfaceBackground:rgba(255, 255, 255, 0.71);
     --workSpaceSurfaceColor:black;
     --workSpaceSurfaceOpacity:black;

     //widget
     --widgetBackgroundColor:#ffffff;
     --widgetSeparatorColor: #B9B7B7;
     --widgetActiveTabColor:rgb(36, 0, 82);
     --widgetInActiveTabColor:#dcdcdcdc;
     --widgetColor:rgba(255,255,255,1);
     --widgetBackgroundOpacity:black;
     --widgetEditableTextColor:black;
     --widgetEditableBackground:#2400524a;
     --widgetReadOnlyTextColor:black;
     --widgetDisabledTextColor:black;
     --widgetHelpertextColor:black;
     --widgetErrortextColor:black;
     --widgetInfoIconActiveColor:black;
     --widgetInfoIconColor:white;
     --widgetInfoIconDisableColor:black;
     --widgetActionIconActiveColor:black;
     --widgetActionIconColor:black;
     --widgetActionIconDisableColor:black;

      //widget buttons
     --widgetNonActiveBackgroundColor: transparent;
     --widgetNonActiveButtonColor: #405169;
     --widgetButtonActiveBackgroundColor: #EF4158;
     --widgetButtonActiveColor: #ffffff;
     --widgetButtonHoverBackgroundColor: #CFCFCF;
     --widgetButtonHoverColor: #ffffff;

     //widgetTableHeader
     --widgetTableHeaderColor: #7e7e7e;
     --widgetTableDataColor: #555a60;

     //Widgets mobile
     --widgetMobileBackgroundColor: #ffffff;
     --widgetMobileLogoColor: #054085;
     --widgetMobileLogoBackgroundColor: #D5E0FC;
     --widgetMobileColor: #000000;
     --widgetMobileStatusColor: #00852D;
     --widgetMobileStatusBackgroundColor: #BBFDB6;
     --widgetMobileFieldColor: #868686;

     //woorklogs
     --worklogsBackgroundColor: #FFFFFF;
     --worklogsProgressColor: #1B1B1B;
     --worklogsDataColor: #555A60;
     --worklogsStepActivityColor: #636363;

     //actions
     --actionsBackgroundColor: #555A60;
     --actionsColor: #ffffff;
     --actionsMobileBackgroundColor: #ffffff;
     --actionsMobileColor: #000000;

     //infoSection
     --infoSectionBackroundColor: #555A60;
     --infoeSectionColor: #FFFFFF;
     --infoSectionHeaderColor: #000000;
     --infoSectionMobileBackgroundColor: #ffffff;
     --infoSectionMobileColor: #000000;
     --infoSectionMobileHeaderColor: #222222;
     --workspaceTooltipBackgroundColor:#555A60;
     --workspaceTooltipColor:#ffffff;

      //popup
     --widgetPopUpBackgroundColor: #ffffff;
     --widgetPopUpColor: #000000;
     --widgetPopupButtonNonActiveBackgroundColor: #EF4158;
     --widgetPopupButtonActiveColor:#ffffff;
     --widgetPopupButtonNonActiveColor: #EF4158;
     --widgetPopupButtonActiveColor: #FFFFFF;
     --widgetPopupButtonActiveBackgroundColor: #EF4158;
     --widgetPopupDropdownHoverColor: #E3E3E3;

     //new variable
     --overlayColor:#0006;
     --sidebarColor:#240052;
     --companyColor:#ffffff1a;
     --companyName : 'ABC Finance Corporation';
     --hoverColor:orange;
     --hoverFilterColor:invert(80%) sepia(34%) saturate(6329%) hue-rotate(358deg) brightness(100%) contrast(107%);
     --workspaceColor:rgb(235,235,235,0.9);
     --widgetBasicInfoTextColor:#F59084;
     --widgetDetailedInfoTextColor:#054796;
     --workspaceButtonActiveFilterBackgroundColor:invert(36%) sepia(91%) saturate(1969%) hue-rotate(327deg) brightness(95%) contrast(98%);
     --widgetActionIconFilterColor: invert(0%) sepia(94%) saturate(26%) hue-rotate(19deg) brightness(105%) contrast(105%);
     --workspaceActionIconFilterColor: invert(0%) sepia(94%) saturate(26%) hue-rotate(19deg) brightness(105%) contrast(105%);
     --sideBarNonActiveFilterColor:invert(92%) sepia(92%) saturate(26%) hue-rotate(249deg) brightness(106%) contrast(100%);
     --backgroundActionIconFilterColor:invert(92%) sepia(92%) saturate(26%) hue-rotate(249deg) brightness(106%) contrast(100%);
     --workSpaceSurfaceIconFilterColor:invert(0%) sepia(94%) saturate(26%) hue-rotate(19deg) brightness(105%) contrast(105%);
     --workSpaceSurfaceTextColor: #000000;
     --widgetEditableTextWidgetColor:#555a60;
     --widgetEditableColor: #2400524a;
     --infoContainerButtonColor: #ef4158;
     --infoStepProgressDisbaleStepColor: #cfcfcf;
     --caseInfoTextColor: black;
     --componentColor:white;
     --workspaceComponentInfoTextColor:blue;
     --workspaceComponentOutlineColor:#9c9c9c;
     --componentTypographyPlaceholderColor:#565656;
     --componentTypographyBasicInfoTextColor:black;
     --workspaceComponentSeparatorColor:#E1D9EC;
     --workspaceComponentStatusTextColor:red;
     --componentActionIconHoverColor:grey;
     --componentListHoverColor:purple;
     --workspaceComponentInfoButtonColor:white;
     --workspaceButtonActiveFilterColor:invert(100%) sepia(0%) saturate(7456%) hue-rotate(173deg) brightness(96%) contrast(110%);
     --workspaceButtonActiveBackgroundColor:var(--secondaryColor);
     --workspaceNonActiveButtonColor:grey;
     --workspaceNonActiveButtonColor:black;
     --workspaceButtonActiveColor:var(--typographySecondaryColor);
     --componentInfoIconActiveFilterColor: invert(0%) sepia(94%) saturate(26%) hue-rotate(19deg) brightness(105%) contrast(105%);
     --componentInfoIconFilterColor: invert(0%) sepia(94%) saturate(26%) hue-rotate(19deg) brightness(105%) contrast(105%);
     --componentInfoIconDisableFilterColor: invert(0%) sepia(94%) saturate(26%) hue-rotate(19deg) brightness(105%) contrast(105%);
     --componentInfoIconHoverFilterColor: invert(0%) sepia(94%) saturate(26%) hue-rotate(19deg) brightness(105%) contrast(105%);
     --componentActionIconFilterColor: invert(0%) sepia(94%) saturate(26%) hue-rotate(19deg) brightness(105%) contrast(105%);
     --componentActionIconActiveFilterColor: invert(0%) sepia(94%) saturate(26%) hue-rotate(19deg) brightness(105%) contrast(105%);
     --componentActionIconDisableFilterColor: invert(44%) sepia(1%) saturate(318%) hue-rotate(8deg) brightness(99%) contrast(91%);
     --componentActionIconHoverFilterColor: invert(0%) sepia(94%) saturate(26%) hue-rotate(19deg) brightness(105%) contrast(105%);
     --workspaceComponentCarouselButtonColor:invert(44%) sepia(1%) saturate(318%) hue-rotate(8deg) brightness(99%) contrast(91%);
     --workspaceComponentCarouselBackground:#e9e8ec;
     --workspaceInfoInactiveIconColor:grey;
     --workspaceComponentInfoColor:linear-gradient(100.22deg, rgba(255, 255, 255, 0.456) 3.83%, rgba(255, 255, 255, 0.6) 93.62%);
     --componentCheckedIconColor:invert(44%) sepia(1%) saturate(318%) hue-rotate(8deg) brightness(99%) contrast(91%);
     --caseInfoProductNameColor: grey;
     --caseInfoTextIconFilterColor: invert(0%) sepia(94%) saturate(26%) hue-rotate(19deg) brightness(105%) contrast(105%);
     --caseInfoColor: rgba(235, 235, 235, 0.7);
     --infoStepProgressDisbaleStepFilterColor: invert(89%) sepia(1%) saturate(576%) hue-rotate(316deg) brightness(90%) contrast(92%);
     --infoStepProgressActiveStepColor: #c5c5c5;
     --infoStepProgressStepNameIconColor: invert(100%) sepia(0%) saturate(7456%) hue-rotate(173deg) brightness(96%) contrast(110%);
     --infoStepProgressStepInactiveColor: white;
     --infoStepProgressStepNameColor: white;
     --infoStepProgressColor:rgba(235, 235, 235, 0.2);
     --componentEditableTextColor:black;
     --workspaceSwitchTextColor:white;
     --workspaceNextButtonColor:white;
     --workspaceNextButtonBackgroundColor:#f59084;
     --workspaceSwitchBackgroundColor:#142e71;
     --workspaceInfoInactiveIconFilterColor:invert(0%) sepia(94%) saturate(26%) hue-rotate(19deg) brightness(105%) contrast(105%);
     --widgetInfoIconFilterColor:invert(0%) sepia(94%) saturate(26%) hue-rotate(19deg) brightness(105%) contrast(105%);

     //header
     --headerTitleColor:#ffffff;
     --headerTitleBackgroundColor: #ffffff;
     --headerTitleOpacity:0.1;
     --error-font-size:8px;

     //FavouriteProduct
     --favouriteProductBackgroundColor: #ffffff;
     --favouriteProductColor: #555a60;

     //Filter
     --filterBackgroundColor: #ffffff;
     --filterColor:#000000;
     --filterActiveButtonBackgroundColor: #EF4158;
     --filterActiveButtonColor: #F1F1F1;
     --filterNonActiveButtonColor: #666666;
     --filterNonActiveButtonBackgroundColor: #cccccc;
     --filterHeaderTitleColor: #222222;

     //sort
     --sortBackgroundColor: #555A60;
     --sortColor: #ffffff;
     --sortMobileBackgroundColor: #ffffff;
     --sortMobileColor: #000000;

     //Component
     --componentBackgroundOpacity:black;
     --componentNameColor:black;
     --componentNameColorOpacity:black;
     --componentInfoIconActiveColor:black;
     --componentInfoIconColor:black;
     --componentInfoIconDisableColor:black;
     --componentActionIconActiveColor:black;
     --componentActionIconColor:black;
     --componentActionIconDisableColor:black;
     --componentLabelColor:#565656;
     --componentbuttonBackgroundColor:rgb(171, 209, 127);
     --componentbuttonTextColor:rgb(243, 241, 241);
     --componentEditableTextColor: #000000;
     --componentReadOnlyTextColor:#DCDCDC;
     --componentDisabledTextColor:rgb(80, 78, 104);
     --componentHelpertextColor:rgb(156, 180, 148);
     --componentErrortextColor:rgb(175, 71, 71);

     //overlays
     --overlayBackgroundColor:rgba(0, 0, 0, 0.4);
     --overlayBackgroundOpacity:0.4;
     --overlayBackgroundBlurColor:black;
     --overlayBackgroundBlurOpacity:black;
     --overlayEditableTextWidgetColor:black;
     --overlayReadOnlyTextColor:black;
     --overlayDisabledTextColor:black;
     --overlayHelpertextColor:black;
     --overlayErrortextColor:black;
     --overlayQuickActionIconColor:black;
     --overlayInfoIcon:black;
     --overlayButtonBackgroundColor:black;
     --overlayButtonColor:black;

     //Info container
     --infoContainerBackbuttonColor:#ffff;
     --infoContainerBackbuttonBackgroundColor:#EF4158;
     --infoContainerBackbuttonBackgroundOpacity:black;
     --infoStepProgressBackgroundColor:black;
     --infoStepProgressCurrentStepColor:black;
     --infoStepProgressPreviousStepColor:black;
     --infoStepProgressFutureStepColor:black;
     --infoTextColor:black;

     //Company variables
     --companySurfaceColor:rgba(255, 255, 255, 0.1);
     --companyNameColor:white;
     --companySurfaceOpacity:0.5;
     --componentBackgroundColor: white;
    //  --companyBackgroundImage:url(../../../assets/images/background.png);
    //  --companyLogoImage:url(../../../assets/images/sample-logo.png);
     --searchBarColor: #ffffff;
     --searchBarBackground:black;
     --backgroundInfoIconActiveColor:black;
     --backgroundInfoIconColor:black;
     --backgroundInfoIconDisableColor:black;
     --backgroundActionIconActiveColor:black;
     --backgroundActionIconColor:white;
     --backgroundActionIconDisableColor:black;

}

@import "./assets/css/root.scss";
@import "./assets/css/global.scss";
@import "./assets/icons/style.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "~font-awesome/css/font-awesome.css";
@import "./assets/fonts/Montserrat/style.css";
@import "./assets/fonts/Nunito/style.css";
@import "./assets/fonts/Eina3-fonts/style.css";
@import "./assets/fonts/Roboto/style.css";
@import "./assets/fonts/Eina3-fonts/style.css";
@import "./app/modules/core/ui-elements/material-custom-theme.scss";
@import "~nouislider/distribute/nouislider.min.css";
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import "./assets/fonts/Source_Sans_Pro/style.css";

// VG grid
@import "./assets/css/vg-grid.compiled.min.css";

html,
body {
  margin: 0px;
  padding: 0px;
  border: 0px;
  height: 100%;
  font-size: var(--basefont);
}

body.prevent-scroll {
  overflow-x: hidden;
}

html p {
  margin-bottom: 0;
}

html label {
  margin-bottom: 0;
}

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

// for TABLET always landscape mode will be triggered
@media screen and (min-device-width: 500px) and (max-device-width: 1100px) and (orientation: portrait) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh !important;
    height: 100vw !important;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}
