:root{
    // Material Theme UI-Elments Variables
    --materialPrimaryFontSize:14px;
    --materialSecondaryFontsize:12px;
    --materialHeaderColor:#fff;
    --materialLogoUrl:url(../../assets/images/vanguard_logo.svg);
    --materialFooterImageUrl:url(../../assets/images/vanguard_logo.svg);
    --materialBckgroundColor:#c2c2c2;
    --materialPrimaryColor:#444;
    --materialSecondaryColor:#999;
    --materialDefaultColor:#a7a7a7;
    --materialPrimaryActiveColor:#537DA5;
    --materialSecondaryActiveColor:rgb(155, 181, 206);
    --materialPrimaryDisabledColor:#c2c2c2;
    --materialSecondaryDisabledColor:f7f7f7;
    --materialDefaultColor:rgba(68,68,68,0.6);

  //  Grid Theme Variables
  --gridPrimaryFontSize:14px;
  --gridSecondaryFontSize:#12px;
  --gridLogoUrl:url(../../assets/images/vanguard_logo.svg);
  --gridFooterUrl:url(../../assets/images/vanguard_logo.svg);
  --gridBackgroundColor:#c7c7c7;
  --gridhHeaderColor:#a7a7a7;
  --gridPrimaryColor:#444;
  --gridSecondaryColor:#999;
  --gridPrimaryActivecolor:#537DA5;
  --gridSecondaryActiveColor:rgb(155, 181, 206);
  --gridPrimaryDisabledColor:#c7c7c7;
  --gridSecondaryDisabledColor:#c2c2c2;
  --gridDefaultColor:rgba(68,68,68,0.6);

  // Classic Theme Variables
  --classicSpaceBetween:8px 0;
  --classicPrimaryfontSize:14px;
  --classicWidth:100%;
  --classicMaxWidth:800px;
  --classicFormMaxWidth:80%;
  --classicLogoUrl:url(../../assets/images/demobanklogo.svg);
  --classicBodyBackgroundImageUrl:url(../../app/modules/themes/assets/images/application-form/header-bg.jpg);
  --classicPrimaryColor:#04A987;
  --classicPrimaryErrorColor:#d80d0d;
  --classicSecondaryErrorColor:#a94442;
  --classicPrimaryBorderColor:#04A987;;
  --classicSecondaryBorderColor:#3c763d;
  --classicheaderbackground:#04A987;
  --classicHeaderPrimaryColor:#fdf74b;
  --classicPrimaryButtonColor:#faa842;
  --classicWhite:#fff;
  --classicPrimaryGrey:#bbb;
  --classicSecondaryGrey:#bdbdbd;
  --classicDefaultGrey:#555;
  --classicpriceSignColor:#eee;
  --classicPriceSignFocus:#dff0d8;
  --classicPriceSignError:#f2dede;

  // Modern
  // Layout
  --modernHeaderLogo: url(../../assets/images/demobanklogo_white.svg);
  --modernLayoutIconsColor: #8f9fe6;
  --modernLayoutProfileNameColor: #ffffff;
  --modernLayoutPadding: 0px 36px 32px 36px;

  // Application form
  --modernBackground: #212d5b;
  --modernPrimaryTextColor: #263166;
  --modernSecondaryTextColor: #63687c;
  --modernHeaderTextColor: #444444;
  --modernElementLabelColor: #585858;
  --modernElementTextColor: #5d687c;
  --modernElementBorderColor: #c5cae2;
  --modernProgressSeperatorColor: #dce1f7;
  --modernAppProgressBackground: #f2f4fc;
  --modernAppFormBackground: #ffffff;
  --modernCompleteProgressbarBackground: linear-gradient(to right, #7bd7f2, #91dcf6, #a5e1fa, #b8e7fd, #c9ecff);
  --modernCompleteProgressbarTextColor: #333333;
  --modernContinueButtonColor: #43d2fd;
  --modernContinueButtonTextColor: #ffffff;
  --modernScrollbarThumb: rgba(255, 255, 255, 0.04);
  --modernScrollbarTrack: rgba(167, 184, 217, 0.29);
  --modernActiveProgressBar: #ffffff;
  --modernActiveProgressBarHighlight: #f7941d;

  // Dashboard
  --modernTabActive: #393939;
  --modernTabInactive: #727272;
  --modernProductTypeOneBackground: #ffd4aa;
  --modernProductTypeTwoBackground: #9cfdfd;
  --modernBorderColor:#C5CAE2;
  --modernProgressApplicationBackgorund: #e4f7cb;
  --modernSubmittedApplicationBackgorund: #f4e9d7;
  --modernCompleteActionBackground: #263166;
  --modernDashboardProductbackground: #ffffff;
  --modernDashnoardbackground:#f2f2f2;
  --modernTableHeaderBackground: #e7ecf7;
  --modernTableHeaderTextColor:#393939;
  --modernDashboardBacgroundForMobile : #f5f6f9;
  --modernDashboardBoxShadow: #c9d2ed;
  --modernDashboardNavbarBackgroundForMobile: #E0E5FF;
  --modernDashboardStatusBackgroundForMobile:#d2fce0;
  --modernDashboardStatusColorForMobile:#01a573;
}
