$width: 100%;
$max-input-width: 800px;
$material-space-between-elements: 0 0 10px 0;

$material-label-color: var(--typographySurfaceColor700);
$material-text-color: var(--typographySurfaceColor500);
$material-border-on-focus: var(--secondaryColor);
$material-error-color: var(--typographyErrorColor700);
$material-border-color: var(--typographySurfaceColor700);
$material-placeholder-color: var(--typographySurfaceColor700);
$material-font-family: Roboto;
$material-input-label: 15.4px;
$material-error-font-size  : 12px;
$material-label-font-size: 12.55px;



/* input color class */
::ng-deep input.mat-input-element {
    color: $material-text-color;
    font-family: var(--Modren-content);
}

/* Teaxt-area input color */
::ng-deep textarea.mat-input-element {
    color: $material-text-color;
}

/* for dropdown values*/
::ng-deep .mat-select-value{
    // color: $material-text-color;
}

/* Change label color without focused */
::ng-deep .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-empty.mat-form-field-label{
    color: $material-label-color;
}

/* Change label color on focused */
// ::ng-deep .mat-form-field.mat-focused .mat-form-field-label {
//     color: $material-border-on-focus !important;
// }

/* underline border color on focused */
::ng-deep .mat-focused .mat-form-field-underline .mat-form-field-ripple {
    background-color: $material-border-on-focus !important;
}

/* mat-label error style */
::ng-deep .mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: $material-error-color !important;
}

// mat-input error outline color
::ng-deep .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick{
    color: $material-error-color !important;
}

// mat-input outline color
::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline{
  color: $material-border-color !important;
}

// mat-input label color
::ng-deep .mat-form-field-label {
  color: $material-placeholder-color !important;
  font-family: var(--Modren-content);
}

//mat-dropdown input field
::ng-deep .mat-form-field-hide-placeholder .mat-select-placeholder{
    font-family: var(--Modren-content);
}

//mat-error msg control
::ng-deep .mat-form-field-subscript-wrapper {
    font-size: $material-error-font-size ;
    font-family: $material-font-family;
}

::ng-deep .mat-form-field-appearance-standard .mat-form-field-flex {
     padding-top: 0px; 
}

//Padding removed in all modern elements apart from it's error
.modern-paddingremove {
    ::ng-deep .mat-form-field-wrapper {
      padding-bottom: 0px;
    //   backdrop-filter: blur(5px);
    }
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-wrapper{
    // backdrop-filter: blur(5px) !important;
}

