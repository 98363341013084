@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?x45imk');
  src:  url('fonts/icomoon.eot?x45imk#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?x45imk') format('truetype'),
    url('fonts/icomoon.woff?x45imk') format('woff'),
    url('fonts/icomoon.svg?x45imk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cancel:before {
  content: "\e900";
}
.icon-phone:before {
  content: "\e0cd";
}
.icon-mail_outline:before {
  content: "\e0e1";
}
.icon-flip_camera_ios:before {
  content: "\e901";
}
.icon-videocam_off:before {
  content: "\e902";
}
.icon-videocam:before {
  content: "\e903";
}
.icon-call_end:before {
  content: "\e904";
}
.icon-mic_off:before {
  content: "\e905";
}
.icon-mic:before {
  content: "\e906";
}
.icon-phonelink_off:before {
  content: "\e907";
}
.icon-snooze:before {
  content: "\e908";
}
.icon-money-icon-2:before {
  content: "\e909";
}
.icon-application:before {
  content: "\e90a";
}
.icon-approvedApplication:before {
  content: "\e90b";
}
.icon-cancelledApplication:before {
  content: "\e90c";
}
.icon-createApplication:before {
  content: "\e90d";
}
.icon-createLeads:before {
  content: "\e90e";
}
.icon-dashboard:before {
  content: "\e90f";
}
.icon-holdApplication:before {
  content: "\e910";
}
.icon-leads:before {
  content: "\e911";
}
.icon-rejectedApplication:before {
  content: "\e912";
}
.icon-uploaddocument:before {
  content: "\e913";
}
.icon-pendingqueries:before {
  content: "\e914";
}
.icon-myapplication:before {
  content: "\e915";
}
.icon-analytics:before {
  content: "\e916";
}
.icon-Audit:before {
  content: "\e917";
}
.icon-Export-Excel:before {
  content: "\e918";
}
.icon-phone_forwarded:before {
  content: "\e919";
}
.icon-more-horizontal:before {
  content: "\e91a";
}
.icon-Cancel:before {
  content: "\e91b";
}
.icon-Complete:before {
  content: "\e91c";
}
.icon-Initiate:before {
  content: "\e91d";
}
.icon-Re-initiate:before {
  content: "\e91e";
}
.icon-savetick:before {
  content: "\e91f";
}
.icon-appfillaction:before {
  content: "\e920";
}
.icon-Application-release-icon_2:before {
  content: "\e921";
}
.icon-Assign-to-me:before {
  content: "\e922";
}
.icon-Leads-1:before {
  content: "\e923";
}
.icon-IG-Logo-only:before {
  content: "\e924";
}
.icon-Intellegrow-title-only:before {
  content: "\e925";
}
.icon-Re-Initiate:before {
  content: "\e926";
}
.icon-Send2:before {
  content: "\e927";
}
.icon-attachments:before {
  content: "\e928";
}
.icon-Close:before {
  content: "\e929";
}
.icon-Delete:before {
  content: "\e92a";
}
.icon-Down_Arrow:before {
  content: "\e92b";
}
.icon-down_filled_arrow:before {
  content: "\e92c";
}
.icon-edit:before {
  content: "\e92d";
}
.icon-edit2:before {
  content: "\e92e";
}
.icon-Notes:before {
  content: "\e92f";
}
.icon-Plus-thin:before {
  content: "\e930";
}
.icon-right-arrow:before {
  content: "\e931";
}
.icon-Tick:before {
  content: "\e932";
}
.icon-up_arrow:before {
  content: "\e933";
}
.icon-vanguard-logo:before {
  content: "\e934";
}
.icon-vanguard-title:before {
  content: "\e935";
}
.icon-action:before {
  content: "\e936";
}
.icon-Alert-2_white:before {
  content: "\e937";
}
.icon-Alert:before {
  content: "\e938";
}
.icon-All-App:before {
  content: "\e939";
}
.icon-All-App_outline:before {
  content: "\e93a";
}
.icon-Green-Tick:before {
  content: "\e93b";
}
.icon-Applications:before {
  content: "\e93c";
}
.icon-chat-icon:before {
  content: "\e93d";
}
.icon-checklist-2:before {
  content: "\e93e";
}
.icon-checklist:before {
  content: "\e93f";
}
.icon-Close1:before {
  content: "\e940";
}
.icon-Tick-cirlce-nonfilled:before {
  content: "\e941";
}
.icon-COMMENTS:before {
  content: "\e942";
}
.icon-dashboard1:before {
  content: "\e943";
}
.icon-dashboard_outline:before {
  content: "\e944";
}
.icon-Delete1:before {
  content: "\e945";
}
.icon-Documents:before {
  content: "\e946";
}
.icon-down-arrow:before {
  content: "\e947";
}
.icon-Download:before {
  content: "\e948";
}
.icon-Filled-uparrow2:before {
  content: "\e949";
}
.icon-Edit:before {
  content: "\e94a";
}
.icon-female:before {
  content: "\e94b";
}
.icon-Filter:before {
  content: "\e94c";
}
.icon-When_circle2:before {
  content: "\e94d";
}
.icon-Green_Tick_filled:before {
  content: "\e94e";
}
.icon-Grid:before {
  content: "\e94f";
}
.icon-grid_custom:before {
  content: "\e950";
}
.icon-Lock-Icon:before {
  content: "\e951";
}
.icon-left-arrow:before {
  content: "\e952";
}
.icon-List:before {
  content: "\e953";
}
.icon-Lock:before {
  content: "\e954";
}
.icon-uniE921:before {
  content: "\e955";
}
.icon-Log-off:before {
  content: "\e956";
}
.icon-Male:before {
  content: "\e957";
}
.icon-my-application:before {
  content: "\e958";
}
.icon-my-application_outline:before {
  content: "\e959";
}
.icon-New-Application:before {
  content: "\e95a";
}
.icon-note:before {
  content: "\e95b";
}
.icon-notification:before {
  content: "\e95c";
}
.icon-notification_filled:before {
  content: "\e95d";
}
.icon-noun_chat_711327:before {
  content: "\e95e";
}
.icon-noun_Document_1570103:before {
  content: "\e95f";
}
.icon-Other:before {
  content: "\e960";
}
.icon-pending_initial_start:before {
  content: "\e961";
}
.icon-plus-filled:before {
  content: "\e962";
}
.icon-plus-outline:before {
  content: "\e963";
}
.icon-uniE930:before {
  content: "\e964";
}
.icon-uniE931:before {
  content: "\e965";
}
.icon-uniE932:before {
  content: "\e966";
}
.icon-uniE933:before {
  content: "\e967";
}
.icon-uniE934:before {
  content: "\e968";
}
.icon-uniE935:before {
  content: "\e969";
}
.icon-uniE936:before {
  content: "\e96a";
}
.icon-uniE937:before {
  content: "\e96b";
}
.icon-Queries:before {
  content: "\e96c";
}
.icon-filter:before {
  content: "\e96d";
}
.icon-rejected-Application:before {
  content: "\e96e";
}
.icon-rejected-Application_outline:before {
  content: "\e96f";
}
.icon-Hand-with-circle:before {
  content: "\e970";
}
.icon-Search:before {
  content: "\e971";
}
.icon-show-more-button-with-three-dots:before {
  content: "\e972";
}
.icon-teams:before {
  content: "\e973";
}
.icon-teams_outline:before {
  content: "\e974";
}
.icon-my-application_outline2:before {
  content: "\e975";
}
.icon-tick_Circle:before {
  content: "\e976";
}
.icon-Vanguard-Blue-logo:before {
  content: "\e977";
}
.icon-Vanguard-logo-CaptiaL:before {
  content: "\e978";
}
.icon-Vanguard-logo-Small:before {
  content: "\e979";
}
.icon-VANGUARD-LOGO-white-no-title:before {
  content: "\e97a";
}
.icon-VANGUARD-LOGO-white-with-title:before {
  content: "\e97b";
}
.icon-vanguard_logo:before {
  content: "\e97c";
}
.icon-Waving-Hand:before {
  content: "\e97d";
}
.icon-uniE94A:before {
  content: "\e97e";
}
.icon-uniE94B:before {
  content: "\e97f";
}
.icon-Chat:before {
  content: "\e980";
}
.icon-Close11:before {
  content: "\e981";
}
.icon-down-arrow-blue:before {
  content: "\e982";
}
.icon-redo2:before {
  content: "\e983";
}
.icon-Left-Arrow-grey:before {
  content: "\e984";
}
.icon-Logo:before {
  content: "\e985";
}
.icon-Application_laptop:before {
  content: "\e986";
}
.icon-Right-arrow-white:before {
  content: "\e987";
}
.icon-Left-arrow-grey-no-line:before {
  content: "\e988";
}
.icon-right-arrow-orange:before {
  content: "\e989";
}
.icon-Tick1:before {
  content: "\e98a";
}
.icon-right-arrow1:before {
  content: "\e98b";
}
.icon-right-arrow_white:before {
  content: "\e98c";
}
.icon-Undo2:before {
  content: "\e98d";
}
.icon-uniE95A:before {
  content: "\e98e";
}
.icon-uniE95B:before {
  content: "\e98f";
}
.icon-Left-Arrow-Black:before {
  content: "\e990";
}
.icon-RAG-Box:before {
  content: "\e991";
}
.icon-Plus-icon:before {
  content: "\e992";
}
.icon-calender:before {
  content: "\e993";
}
.icon-Female:before {
  content: "\e994";
}
.icon-male:before {
  content: "\e995";
}
.icon-minus:before {
  content: "\e996";
}
.icon-other:before {
  content: "\e997";
}
.icon-Thin-Plus:before {
  content: "\e998";
}
.icon-Delete11:before {
  content: "\e999";
}
.icon-edit1:before {
  content: "\e99a";
}
.icon-Filled-uparrow:before {
  content: "\e99b";
}
.icon-If_diamond:before {
  content: "\e99c";
}
.icon-redo:before {
  content: "\e99d";
}
.icon-Search1:before {
  content: "\e99e";
}
.icon-then_square:before {
  content: "\e99f";
}
.icon-Undo:before {
  content: "\e9a0";
}
.icon-When_circle:before {
  content: "\e9a1";
}
.icon-Related-app-1:before {
  content: "\e9a2";
}
.icon-Calender:before {
  content: "\e9a3";
}
.icon-Upload-Icon:before {
  content: "\e9a4";
}
.icon-Upload-Icon-Cloud:before {
  content: "\e9a5";
}
.icon-pdf-file:before {
  content: "\e9a6";
}
.icon-xls-file:before {
  content: "\e9a7";
}
.icon-doc-file:before {
  content: "\e9a8";
}
.icon-Close_circle:before {
  content: "\e9a9";
}
.icon-thin-plus-circle:before {
  content: "\e9aa";
}
.icon-message:before {
  content: "\e9ab";
}
.icon-vanguard-logo1:before {
  content: "\e9ac";
}
.icon-vanguard-title1:before {
  content: "\e9ad";
}
.icon-action1:before {
  content: "\e9ae";
}
.icon-Alert-2_white1:before {
  content: "\e9af";
}
.icon-Alert1:before {
  content: "\e9b0";
}
.icon-All-App1:before {
  content: "\e9b1";
}
.icon-All-App_outline1:before {
  content: "\e9b2";
}
.icon-Green-Tick1:before {
  content: "\e9b3";
}
.icon-Applications1:before {
  content: "\e9b4";
}
.icon-chat-icon1:before {
  content: "\e9b5";
}
.icon-checklist-21:before {
  content: "\e9b6";
}
.icon-checklist1:before {
  content: "\e9b7";
}
.icon-Close2:before {
  content: "\e9b8";
}
.icon-Tick-cirlce-nonfilled1:before {
  content: "\e9b9";
}
.icon-COMMENTS1:before {
  content: "\e9ba";
}
.icon-dashboard11:before {
  content: "\e9bb";
}
.icon-dashboard_outline1:before {
  content: "\e9bc";
}
.icon-Delete2:before {
  content: "\e9bd";
}
.icon-Documents1:before {
  content: "\e9be";
}
.icon-down-arrow1:before {
  content: "\e9bf";
}
.icon-Download1:before {
  content: "\e9c0";
}
.icon-Edit1:before {
  content: "\e9c1";
}
.icon-female1:before {
  content: "\e9c2";
}
.icon-Filter1:before {
  content: "\e9c3";
}
.icon-Green_Tick_filled1:before {
  content: "\e9c4";
}
.icon-Grid1:before {
  content: "\e9c5";
}
.icon-grid_custom1:before {
  content: "\e9c6";
}
.icon-left-arrow1:before {
  content: "\e9c7";
}
.icon-List1:before {
  content: "\e9c8";
}
.icon-Lock1:before {
  content: "\e9c9";
}
.icon-uniE9211:before {
  content: "\e9ca";
}
.icon-Log-off1:before {
  content: "\e9cb";
}
.icon-Male1:before {
  content: "\e9cc";
}
.icon-my-application1:before {
  content: "\e9cd";
}
.icon-my-application_outline1:before {
  content: "\e9ce";
}
.icon-New-Application1:before {
  content: "\e9cf";
}
.icon-note1:before {
  content: "\e9d0";
}
.icon-notification1:before {
  content: "\e9d1";
}
.icon-notification_filled1:before {
  content: "\e9d2";
}
.icon-noun_chat_7113271:before {
  content: "\e9d3";
}
.icon-noun_Document_15701031:before {
  content: "\e9d4";
}
.icon-Other1:before {
  content: "\e9d5";
}
.icon-pending_initial_start1:before {
  content: "\e9d6";
}
.icon-plus-filled1:before {
  content: "\e9d7";
}
.icon-plus-outline1:before {
  content: "\e9d8";
}
.icon-uniE9301:before {
  content: "\e9d9";
}
.icon-uniE9311:before {
  content: "\e9da";
}
.icon-uniE9321:before {
  content: "\e9db";
}
.icon-uniE9331:before {
  content: "\e9dc";
}
.icon-uniE9341:before {
  content: "\e9dd";
}
.icon-uniE9351:before {
  content: "\e9de";
}
.icon-uniE9361:before {
  content: "\e9df";
}
.icon-uniE9371:before {
  content: "\e9e0";
}
.icon-Queries1:before {
  content: "\e9e1";
}
.icon-filter1:before {
  content: "\e9e2";
}
.icon-rejected-Application1:before {
  content: "\e9e3";
}
.icon-rejected-Application_outline1:before {
  content: "\e9e4";
}
.icon-Hand-with-circle1:before {
  content: "\e9e5";
}
.icon-Search2:before {
  content: "\e9e6";
}
.icon-show-more-button-with-three-dots1:before {
  content: "\e9e7";
}
.icon-teams1:before {
  content: "\e9e8";
}
.icon-teams_outline1:before {
  content: "\e9e9";
}
.icon-tick_Circle1:before {
  content: "\e9ea";
}
.icon-Vanguard-Blue-logo1:before {
  content: "\e9eb";
}
.icon-Vanguard-logo-CaptiaL1:before {
  content: "\e9ec";
}
.icon-Vanguard-logo-Small1:before {
  content: "\e9ed";
}
.icon-VANGUARD-LOGO-white-no-title1:before {
  content: "\e9ee";
}
.icon-VANGUARD-LOGO-white-with-title1:before {
  content: "\e9ef";
}
.icon-vanguard_logo1:before {
  content: "\e9f0";
}
.icon-Waving-Hand1:before {
  content: "\e9f1";
}
.icon-uniE94A1:before {
  content: "\e9f2";
}
.icon-uniE94B1:before {
  content: "\e9f3";
}
.icon-Chat1:before {
  content: "\e9f4";
}
.icon-Close111:before {
  content: "\e9f5";
}
.icon-down-arrow-blue1:before {
  content: "\e9f6";
}
.icon-Left-Arrow-grey1:before {
  content: "\e9f7";
}
.icon-Logo1:before {
  content: "\e9f8";
}
.icon-Application_laptop1:before {
  content: "\e9f9";
}
.icon-Right-arrow-white1:before {
  content: "\e9fa";
}
.icon-Left-arrow-grey-no-line1:before {
  content: "\e9fb";
}
.icon-right-arrow-orange1:before {
  content: "\e9fc";
}
.icon-Tick11:before {
  content: "\e9fd";
}
.icon-right-arrow11:before {
  content: "\e9fe";
}
.icon-right-arrow_white1:before {
  content: "\e9ff";
}
.icon-uniE95A1:before {
  content: "\ea00";
}
.icon-uniE95B1:before {
  content: "\ea01";
}
.icon-Left-Arrow-Black1:before {
  content: "\ea02";
}
.icon-RAG-Box1:before {
  content: "\ea03";
}
.icon-Plus-icon1:before {
  content: "\ea04";
}
.icon-calender1:before {
  content: "\ea05";
}
.icon-Female1:before {
  content: "\ea06";
}
.icon-male1:before {
  content: "\ea07";
}
.icon-other1:before {
  content: "\ea08";
}
.icon-Thin-Plus1:before {
  content: "\ea09";
}
.icon-Delete111:before {
  content: "\ea0a";
}
.icon-edit11:before {
  content: "\ea0b";
}
.icon-Filled-uparrow1:before {
  content: "\ea0c";
}
.icon-If_diamond1:before {
  content: "\ea0d";
}
.icon-redo1:before {
  content: "\ea0e";
}
.icon-Search11:before {
  content: "\ea0f";
}
.icon-then_square1:before {
  content: "\ea10";
}
.icon-Undo1:before {
  content: "\ea11";
}
.icon-When_circle1:before {
  content: "\ea12";
}
.icon-Related-app-11:before {
  content: "\ea13";
}
.icon-Calender1:before {
  content: "\ea14";
}
.icon-Upload-Icon1:before {
  content: "\ea15";
}
.icon-Upload-Icon-Cloud1:before {
  content: "\ea16";
}
.icon-pdf-file1:before {
  content: "\ea17";
}
.icon-xls-file1:before {
  content: "\ea18";
}
.icon-doc-file1:before {
  content: "\ea19";
}
.icon-Close_circle1:before {
  content: "\ea1a";
}
.icon-send-button_outline:before {
  content: "\ea1b";
}
.icon-Left-Arrow:before {
  content: "\ea1c";
}
.icon-Right-Arrow:before {
  content: "\ea1d";
}
.icon-Document-View:before {
  content: "\ea1e";
}
.icon-Flag-Long:before {
  content: "\ea1f";
}
.icon-Image:before {
  content: "\ea20";
}
.icon-Line-arrow-down:before {
  content: "\ea21";
}
.icon-Save:before {
  content: "\ea22";
}
.icon-User-icon:before {
  content: "\ea23";
}
.icon-Small-flag:before {
  content: "\ea24";
}
.icon-bot:before {
  content: "\ea25";
}
.icon-Gear-Icon:before {
  content: "\ea26";
}
.icon-my-application2:before {
  content: "\ea27";
}
.icon-Indesign4:before {
  content: "\ea28";
}
.icon-setup2:before {
  content: "\ea29";
}
.icon-UX:before {
  content: "\ea2a";
}
.icon-action2:before {
  content: "\ea2b";
}
.icon-bell:before {
  content: "\ea2c";
}
.icon-flag:before {
  content: "\ea2d";
}
.icon-audit:before {
  content: "\ea2e";
}
.icon-back-arrow:before {
  content: "\ea2f";
}
.icon-forward-arrow:before {
  content: "\ea30";
}
.icon-Mobile:before {
  content: "\ea31";
}
.icon-PC:before {
  content: "\ea32";
}
.icon-Rejected:before {
  content: "\ea33";
}
.icon-Stamp:before {
  content: "\ea34";
}
.icon-Tab:before {
  content: "\ea35";
}
.icon-uniEA10:before {
  content: "\ea36";
}
.icon-uniEA11:before {
  content: "\ea37";
}
.icon-Play-Icon_black:before {
  content: "\ea38";
}
.icon-Progess:before {
  content: "\ea39";
}
.icon-Progess_black:before {
  content: "\ea3a";
}
.icon-Stop-icon:before {
  content: "\ea3b";
}
.icon-Stop-icon_black:before {
  content: "\ea3c";
}
.icon-DEMO-Bank-Logo-black:before {
  content: "\ea3d";
}
.icon-DEMO-Bank-Logo-white:before {
  content: "\ea3e";
}
.icon-uniEA19:before {
  content: "\ea3f";
}
.icon-uniEA1A:before {
  content: "\ea40";
}
.icon-uniEA1B:before {
  content: "\ea41";
}
.icon-uniEA1C:before {
  content: "\ea42";
}
.icon-uniEA1D:before {
  content: "\ea43";
}
.icon-uniEA1E:before {
  content: "\ea44";
}
.icon-uniEA1F:before {
  content: "\ea45";
}
.icon-uniEA20:before {
  content: "\ea46";
}
.icon-uniEA21:before {
  content: "\ea47";
}
.icon-uniEA22:before {
  content: "\ea48";
}
.icon-DEMO-Bank-single:before {
  content: "\ea49";
}
.icon-Demo-bank-title-only:before {
  content: "\ea4a";
}
.icon-DEMO-Bank-white-single:before {
  content: "\ea4b";
}
.icon-Worklog:before {
  content: "\ea4c";
}
.icon-settings:before {
  content: "\ea4d";
}
.icon-language:before {
  content: "\ea4e";
}
.icon-Create-leads-1:before {
  content: "\ea4f";
}
.icon-security:before {
  content: "\ea50";
}
.icon-comment_questions:before {
  content: "\ea51";
}
.icon-view-show:before {
  content: "\ea52";
}
.icon-plus:before {
  content: "\f067";
}
.icon-minus1:before {
  content: "\f068";
}
