@font-face {
    font-family: 'Eina03-Bold';
    src: url('./Eina03-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Eina03-BoldItalic';
    src: url('./Eina03-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Eina03-Light';
    src: url('./Eina03-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Eina03-LightItalic';
    src: url('./Eina03-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Eina03-Regular';
    src: url('./Eina03-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Eina03-RegularItalic';
    src: url('./Eina03-RegularItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Eina03-SemiBold';
    src: url('./Eina03-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Eina03-SemiboldItalic';
    src: url('./Eina03-SemiboldItalic.ttf') format('truetype');
}