//global scss
@import './font.scss';
@import './variables';

html{
  font-size: 14px;
}


.heading-1{
  margin-bottom: 1.4286rem;
  font-weight: 600;
  color: #444444;
}


.error-message{
  color: map-get($variables, error-message-text);
  font-family: $Vanguard_number_font-family-regular;
  font-size: 0.7143rem;
  font-weight: 300;
}


.button-submit {
  background-image: map-get($variables, login-button-background-color);
  border: 0;
  border-radius: 0.2858rem;
  font-size: 1.143rem;
  font-family: $Vanguard_font-family-medium;
}

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
/* Material style */
.button-submit {
  border: none;
  cursor: pointer;
  color: white;
  box-shadow: 0.1429rem 0.1429rem 0.2858rem rgba(0, 0, 0, .4);
}

/* Ripple magic */
.button-submit{
  position: relative;
  overflow: hidden;
}

.button-submit:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.3571rem;
  height: 0.3571rem;
  background: rgba(255, 255, 255, .5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  25% {
    transform: scale(15, 15);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(60, 60);
  }
}

button.button-submit:focus:not(:active)::after {
  animation: ripple 0.4s ease-out;
}

button.button-submit:hover:not(:active)::after {
  animation: ripple 0.4s ease-out;
}


li span{
  outline: none;
}

::-webkit-scrollbar {
  width: 0.4rem;
  height:0.4rem;
  cursor: pointer;
}

::-webkit-scrollbar:hover{
  width: 0.5rem;
  height:0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px lightgrey;
  border-radius: 0.2rem;
  cursor: pointer;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: map-get($variables, scroller-primary);
  border-radius: 0.2rem;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey;
  cursor: pointer;
}


.loader{
  position:absolute;
  top: calc(50% - 5.357rem);
  left: calc(50% - 5.357rem);
  z-index: 999;
  img{
    max-width: 10.714rem;
  }
}

/*Range Style*/
[type="range"]  {
  -webkit-appearance: none;
  // width: 100%;
  height: 0.3571rem;
  background: map-get($variables,  text-secondary );
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  padding: 0;
  margin: 0;
}

[type="date"] :hover {
  opacity: 1;
}

[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 1.0714rem;
  height: 1.0714rem;
  border-radius:50%;
  background:map-get($variables, login-button-background-color);
  cursor: pointer;
}

[type="range"]:hover::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 1.0714rem;
  height: 1.0714rem;
  border-radius:50%;
  background:map-get($variables,login-button-background-color);
  cursor: pointer;

}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: none;
}

body {
  font-family: $Vanguard_font-family-regular;
  color: map-get($variables, primary);
}

@media only screen and (max-width: 560px), (min-device-width: 268px) and (max-device-width: 560px) {
  ::-webkit-scrollbar {
    width: 0px !important;
  }
}

::ng-deep .cdk-overlay-container {
  z-index: 1100 !important;
}
