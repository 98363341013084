$Vanguard_font-family-regular:'Montserrat-Regular';
$Vanguard_font-family-bold: 'Montserrat-Bold';
$Vanguard_font-family-bold-italic: 'Montserrat-BoldItalic';
$Vanguard_font-family-extra-bold: 'Montserrat-ExtraBold';
$Vanguard_font-family-extra-bold-italic: 'Montserrat-ExtraBoldItalic';
$Vanguard_font-family-extra-light: 'Montserrat-ExtraLight';
$Vanguard_font-family-extra-light-italic: 'Montserrat-ExtraLightItalic';
$Vanguard_font-family-italic: 'Montserrat-Italic';
$Vanguard_font-family-light: 'Montserrat-Light';
$Vanguard_font-family-light-italic: 'Montserrat-LightItalic';
$Vanguard_font-family-medium: 'Montserrat-Medium';
$Vanguard_font-family-medium-italic: 'Montserrat-MediumItalic';
$Vanguard_font-family-semi-bold: 'Montserrat-SemiBold';
$Vanguard_font-family-semi-bold-italic: 'Montserrat-SemiBoldItalic';
$Vanguard_font-family-thin: 'Montserrat-Thin';
$Vanguard_font-family-thin-italic: 'Montserrat-ThinItalic';


$Vanguard_number_font-family-regular:'Roboto-Regular';
$Vanguard_number_font-family-bold: 'Roboto-Bold';
$Vanguard_number_font-family-bold-italic: 'Roboto-BoldItalic';
$Vanguard_number_font-family-italic: 'Roboto-Italic';
$Vanguard_number_font-family-light: 'Roboto-Light';
$Vanguard_number_font-family-light-italic: 'Roboto-LightItalic';
$Vanguard_number_font-family-medium: 'Roboto-Medium';
$Vanguard_number_font-family-medium-italic: 'Roboto-MediumItalic';
$Vanguard_number_font-family-thin: 'Roboto-Thin';
$Vanguard_number_font-family-thin-italic: 'Roboto-ThinItalic';


// $Vanguard_font-base:14px;


$Vanguard_font-weight-extra-light:200;
$Vanguard_font-weight-light:300;
$Vanguard_font-weight-regular:400;
$Vanguard_font-weight-medium:500;
$Vanguard_font-weight-semi-bold:600;
$Vanguard_font-weight-bold:700;

