@font-face {
    font-family: 'Nunito-Black';
    src: url('./Nunito-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito-BlackItalic';
    src: url('./Nunito-BlackItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito-Bold';
    src: url('./Nunito-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito-BoldItalic';
    src: url('./Nunito-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito-ExtraBold';
    src: url('./Nunito-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito-ExtraBoldItalic';
    src: url('./Nunito-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito-ExtraLight';
    src: url('./Nunito-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito-ExtraLightItalic';
    src: url('./Nunito-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito-Italic';
    src: url('./Nunito-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito-Light';
    src: url('./Nunito-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito-LightItalic';
    src: url('./Nunito-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito-Regular';
    src: url('./Nunito-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito-SemiBold';
    src: url('./Nunito-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito-SemiBoldItalic';
    src: url('./Nunito-SemiBoldItalic.ttf') format('truetype');
}
